<template>
  <div class="base-popover">
    <!-- toggler -->
    <button
    style="
    background-color: #fff!important;
    border: none;
    margin: 0 8px;
    "
      class=" hint "
      @mouseover="contentVisible = true"
      @mouseleave="contentVisible = false"
    >
      <!-- <span class="icon-help"></span> -->
      <i></i>
    </button>
    <!-- content -->
    <div
      class="
        popover-content
        border-1 border-muted
        shadow
        bg-white
        p-2
        text-left
      "
      v-show="contentVisible"
    >
    <span></span>
    <!-- <div class="arrow"> v-show="contentVisible"</div> -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    contentVisible: false,
  }),
};
</script>

<style lang="scss" scoped>

.hint > i::after {
  content: '?';
  font-style: normal;
  border: solid #00A974 1px;
  color: #00A974;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: block;
  text-align: center;
  font-size: 11px;
  cursor: pointer;
  font-weight: bold;
  line-height: 13px;
}
</style>