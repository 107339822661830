<template>
  <div class="navbar navbar-expand-md navbar--dark">
    <!-- Main navbar -->
    <div class="container">
      <div style="text-align: start!important;" class="navbar-brand">
        <a href="/" class="d-inline-block">
          <!-- <img src="/logo.png" alt="Home"  height="42"/> -->
          <h1 class=" font-weight-bolder logo__custom  ">
            {{site_settings.name }}
          </h1>
        </a>
      </div>

      <div class="d-md-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i class="icon-tree5 text-white "></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="pulse-button " :to="{ name: 'PlaceOrder' }"
              >Place Order</router-link
            >
          </li>

          <!-- <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'BulkOrder' }"
              >Bulk Order</router-link
            >
          </li> -->
          <li class="nav-item" v-if="isAuth">
            <router-link style="color: white !important;" class="navbar-nav-link text-white" :to="{ name: 'Dashboard' }"
              >Home</router-link
            >
          </li>
          <span v-if="isAuth">
            <li class="nav-item py-1" >
              <button class="btn btn-secondary px-3 py-2" @click="doLogout">Logout</button>
            </li>
          </span>
          <li class="nav-item" v-if="!isAuth">
            <router-link style="color: white !important;" class="navbar-nav-link  text-white " :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>

          <li class="nav-item" v-if="!isAuth">
            <router-link style="color: white !important;" class="navbar-nav-link text-white" :to="{ name: 'Register' }"
              >Register</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- /main navbar -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    ...mapState("setups", ["site_settings"]),

    isAuth() {
      if (this.isAuthenticated && this.user && Object.keys(this.user).length > 0) {
        return true
      } else return false
    }
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSiteSettings"]),

  },

  async mounted() {
    this.getSiteSettings()
  },
};
</script>


<style scoped>
.navbar{
  z-index: 100;
}
</style>