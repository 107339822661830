<template>
  <div class="is-loading d-flex justify-content-center align-items-center">
    <!-- <img width="60px" :src="loading_gif"  alt="Loading..."> -->
    <!-- 7 -->
    <!-- <div class="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
      </div> -->
      <span  class=""><svg width="65" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#4CAF50" class="w-full h-full"><g fill="none" fill-rule="evenodd" stroke-width="4"><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle><circle cx="22" cy="22" r="1"><animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate><animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate></circle></g></svg></span>
    <!-- <div class="loader loader--style6" title="5" style="width:60px; height:60px">
     
    </div> -->
  </div>
</template>


<script>
// import loading_gif from "../assets/global_assets/images/loading.gif";
export default {
  name: "BaseLoading",
  data() {
    return {
      // loading_gif: loading_gif,
    };
  },
};
</script>


<style lang="scss">
svg path,
svg rect{
  fill: #009688 ;
}

.loader{
    width: 230px;
    height: 35px;
    position: relative;
    margin: 50px auto 0;
}
.loader span{
    width: 22px;
    height: 22px;
    margin: 0 5px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}
.loader span:nth-child(1){ background: #5CFF5C; }
.loader span:nth-child(2){ background: #00FF00; }
.loader span:nth-child(3){ background: #00A974; }
.loader span:nth-child(4){ background: #18A558; }
.loader span:nth-child(5){ background: #116530; }
.loader span:before{
    content: "";
    background: inherit;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    animation: wave 2s ease-out infinite;
}
.loader span:nth-child(1):before{ animation-delay: 0.2s; }
.loader span:nth-child(2):before{ animation-delay: 0.4s; }
.loader span:nth-child(3):before{ animation-delay: 0.6s; }
.loader span:nth-child(4):before{ animation-delay: 0.8s; }
.loader span:nth-child(5):before{ animation-delay: 1s; }
@keyframes wave{
    50%,75%{ transform: scale(2.5); }
    80%,100%{ opacity: 0; }
}
</style>
