import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Globally register all `_base`-prefixed components
import "./components/index";

// stylings
import "./assets/styles/main.scss";

// filters
require('./filters/index')


// for notifications
import VuePNotify from "vue-pnotify";
Vue.use(VuePNotify);

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

// for api calls
import http from "./modules/http.index";
Vue.prototype.$http = http;
import { Form, HasError, AlertError } from "vform";
Vue.prototype.$Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

// vue editor / rich textarea
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);


window.Event = new Vue();

console.log("process.env", process.env);

Vue.config.productionTip = false;
// Don't warn about using the dev version of Vue in development.
// Vue.config.productionTip = process.env.NODE_ENV === 'production'



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
